<template>
  <svg
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="inherit"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_5_328)">
      <path
        d="M26.8514 39.107C26.8206 39.107 26.8206 39.107 26.7898 39.107C26.6358 39.107 26.5126 39.0454 26.4203 38.9222L14.5034 26.4203C14.2878 26.174 14.2878 25.8045 14.5034 25.5581L18.6297 21.1547C18.7528 21.0316 18.9068 20.97 19.0915 20.97H34.642C34.8267 20.97 34.9807 21.0316 35.1039 21.1547L39.2301 25.5581C39.4457 25.8045 39.4457 26.174 39.2301 26.4203L27.344 38.8915C27.344 38.8915 27.344 38.8915 27.3133 38.9222C27.3133 38.9222 27.3133 38.9222 27.2825 38.9222C27.1593 39.0146 27.0361 39.0762 26.9129 39.0762C26.8822 39.107 26.8514 39.107 26.8514 39.107ZM23.4333 28.4527L27.0669 37.3826L37.906 26.02L34.3648 22.2325H19.3379L15.7967 26.02L25.0654 35.7198L22.2632 28.7914C22.2324 28.699 22.2016 28.6374 22.2324 28.545L22.294 25.9892C22.294 25.6505 22.5711 25.3734 22.9099 25.3734C23.2486 25.3734 23.5257 25.6505 23.5257 25.9892L23.4333 28.4527Z"
        fill="inherit"
      />
      <path
        d="M26.8514 39.107C26.7898 39.107 26.6974 39.107 26.6358 39.0762C26.3279 38.953 26.1739 38.5835 26.2971 38.2756L30.1154 28.4526V26.02C30.1154 25.6813 30.3926 25.4041 30.7313 25.4041C31.07 25.4041 31.3471 25.6813 31.3471 26.02V28.5758C31.3471 28.6374 31.3471 28.7298 31.3163 28.7914L27.4672 38.7067C27.3441 38.953 27.0977 39.107 26.8514 39.107Z"
        fill="inherit"
      />
      <path
        d="M38.7374 26.6359H14.9345C14.5958 26.6359 14.3187 26.3587 14.3187 26.02C14.3187 25.6813 14.5958 25.4041 14.9345 25.4041H38.7374C39.0762 25.4041 39.3533 25.6813 39.3533 26.02C39.3533 26.3587 39.107 26.6359 38.7374 26.6359Z"
        fill="inherit"
      />
      <path
        d="M22.8791 26.6359C22.6943 26.6359 22.5404 26.5435 22.4172 26.4203L18.5989 22.0169C18.3833 21.7706 18.4141 21.3703 18.6604 21.1547C18.9068 20.9392 19.3071 20.97 19.5226 21.2163L22.8483 25.0654L26.3587 21.1855C26.4818 21.0624 26.6358 20.97 26.8206 20.97C27.0053 20.97 27.1593 21.0624 27.2825 21.1855L30.6697 25.0654L34.1185 21.1855C34.334 20.9392 34.7343 20.9084 34.9807 21.1239C35.227 21.3395 35.2578 21.7398 35.0423 21.9861L31.1316 26.3895C31.0084 26.5127 30.8544 26.6051 30.6697 26.6051C30.4849 26.6051 30.331 26.5435 30.2078 26.3895L26.8206 22.5096L23.341 26.4203C23.2178 26.5435 23.033 26.6359 22.8791 26.6359Z"
        fill="inherit"
      />
      <path
        d="M33.595 29.1917H20.4772C20.1385 29.1917 19.8614 28.9145 19.8614 28.5758C19.8614 28.2371 20.1385 27.96 20.4772 27.96H33.6258C33.9645 27.96 34.2417 28.2371 34.2417 28.5758C34.2417 28.9145 33.9645 29.1917 33.595 29.1917Z"
        fill="inherit"
      />
      <path
        d="M23.2486 40H4.77287C3.66433 40 2.58658 39.5689 1.72437 38.7991C0.585036 37.7521 -0.0308228 36.2433 -0.0308228 34.6112C0.0307631 29.4688 1.23169 16.8437 11.1778 10.3772V8.12933L8.65278 0.831409C8.5912 0.646651 8.62199 0.431101 8.74516 0.277136C8.86833 0.123172 9.0223 0 9.23785 0C10.4388 0 11.0854 0.52348 11.5781 0.923788C12.04 1.2933 12.3787 1.57044 13.1485 1.57044C13.9184 1.57044 14.2879 1.2933 14.719 0.923788C15.2425 0.52348 15.8583 0 17.0592 0C18.2602 0 18.9068 0.52348 19.3995 0.923788C19.8614 1.2933 20.2001 1.57044 20.9699 1.57044C21.7398 1.57044 22.1093 1.2933 22.5404 0.923788C23.0639 0.52348 23.6797 0 24.8806 0C25.0654 0 25.281 0.0923788 25.3733 0.246343C25.4965 0.400308 25.5273 0.615858 25.4657 0.800616L22.8483 8.28329V10.3464C25.9276 12.348 28.4218 15.0885 30.2694 18.5373C30.4234 18.8453 30.331 19.2148 30.0231 19.3687C29.7151 19.5227 29.3456 19.4303 29.1917 19.1224C27.3749 15.766 24.9422 13.1178 21.8937 11.2086C21.709 11.0855 21.6166 10.9007 21.6166 10.6851V8.19092C21.6166 8.12933 21.6166 8.03695 21.6474 7.97537L24.0184 1.44727C23.8029 1.57044 23.5873 1.7244 23.3718 1.87837C22.8483 2.27868 22.2325 2.80216 21.0315 2.80216C19.8306 2.80216 19.184 2.27868 18.6913 1.87837C18.2294 1.50885 17.8599 1.23172 17.09 1.23172C16.3202 1.23172 15.9507 1.50885 15.5196 1.87837C14.9961 2.27868 14.3803 2.80216 13.1793 2.80216C11.9784 2.80216 11.3318 2.27868 10.8391 1.87837C10.5927 1.7244 10.408 1.53965 10.1616 1.44727L12.4095 7.8214C12.4403 7.88299 12.4403 7.94457 12.4403 8.03695V10.7159C12.4403 10.9315 12.3171 11.1162 12.1632 11.2394C2.4634 17.3056 1.29327 29.592 1.23169 34.6112C1.23169 35.9045 1.69358 37.0747 2.58658 37.8753C3.20243 38.4604 4.00305 38.7683 4.77287 38.7683H23.2486C23.5873 38.7683 23.8645 39.0454 23.8645 39.3841C23.8645 39.7229 23.5873 40 23.2486 40Z"
        fill="inherit"
      />
      <path
        d="M22.2941 8.62201H11.8245C11.4858 8.62201 11.2086 8.34487 11.2086 8.00615C11.2086 7.66743 11.4858 7.39029 11.8245 7.39029H22.2941C22.6328 7.39029 22.9099 7.66743 22.9099 8.00615C22.9099 8.34487 22.6328 8.62201 22.2941 8.62201Z"
        fill="inherit"
      />
      <path
        d="M22.2941 11.301H11.8245C11.4858 11.301 11.2086 11.0239 11.2086 10.6851C11.2086 10.3464 11.4858 10.0693 11.8245 10.0693H22.2941C22.6328 10.0693 22.9099 10.3464 22.9099 10.6851C22.9099 11.0239 22.6328 11.301 22.2941 11.301Z"
        fill="inherit"
      />
      <path
        d="M14.1339 6.74365C13.8568 6.74365 13.6105 6.55889 13.5489 6.28175L12.5635 2.34026C12.4711 2.00154 12.6867 1.66281 13.0254 1.60123C13.3641 1.50885 13.7028 1.7244 13.7644 2.06312L14.7498 6.00462C14.8422 6.34334 14.6266 6.68206 14.2879 6.74365C14.2263 6.74365 14.1955 6.74365 14.1339 6.74365Z"
        fill="inherit"
      />
      <path
        d="M20.1077 8.62203C20.0769 8.62203 20.0461 8.62203 20.0154 8.62203C19.6766 8.56044 19.4611 8.25251 19.4919 7.91379L19.923 5.11163C19.9846 4.77291 20.2925 4.55736 20.6312 4.58815C20.9699 4.64974 21.1855 4.95767 21.1547 5.29639L20.7236 8.09855C20.662 8.40648 20.4157 8.62203 20.1077 8.62203Z"
        fill="inherit"
      />
    </g>
    <defs>
      <clipPath id="clip0_5_328">
        <rect width="39.3534" height="40" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>
<script>
export default {
  name: 'FiveJackpotsIcon',
}
</script>
